@import "styles/base/reset";

.dark-toggle {
  position: fixed;
  bottom: 40px;
  left: 2em;
  z-index: 1;
}

p {
  font-size: large;
}

li {
  font-size: large;
}
