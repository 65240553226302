@use "styles/abstracts/colors" as *;

.video-container {
  aspect-ratio: 16/9;
}

.video-container iframe {
  width: 100%;
  height: 100%;
}
