@use "styles/abstracts/colors" as *;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "dark": $dark,
);
// @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
@import "bootstrap/scss/bootstrap.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
