@use "styles/abstracts/colors" as *;

.light {
  background-color: $primary;
}

.dark {
  background-color: $secondary;
}

.light div.dropdown-menu.show {
  background-color: $light;
}

.dark div.dropdown-menu.show {
  background-color: $dark;
}
