@use "styles/abstracts/colors" as *;
.background {
  background-repeat: no-repeat;
  background-size: cover;
  height: 25rem;
  // background-attachment: fixed;
  transition: all 0.9s ease;
  background-image: url("/images/tech.png");
}

.dark {
  background-color: $secondary;
  color: $light;

  .background {
    opacity: 0.8;
  }
}

.logo {
  color: $light;
  background-color: transparent;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}

h1.alternate,
h1.alternate:before,
h1.alternate:after {
  color: $light;
  background-color: $light;
}

.icon-100 {
  font-size: 100px;
}
