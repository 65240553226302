@use "styles/abstracts/colors" as *;
.bgimg {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  // background-attachment: fixed;
  // position: fixed;
  background-image: url("/images/lightIntro.jpg");
  background-position: 50% 40%;
  transition: all 0.9s ease;
}

// .light.bgimg {
//   background-image: url("/images/lightIntro.jpg");
//   background-position: 50% 40%;
// }

// .dark.bgimg {
//   background-image: url("/images/darkIntro.jpg");
//   background-position: 50% 10%;
// }

h1 {
  font-size: 50px;
  font-weight: bolder;
}

.light {
  background-color: $primary;
  color: $dark;
}

.dark {
  background-color: $secondary;
  color: $light;

  .bgimg {
    opacity: 0.5;
  }
}
